import React from 'react';
import { useFormContext, UseFormSetValue } from 'react-hook-form';

import { CreateEnergyContractPayload } from '@contexts/energy-contract/types';
import { NumericFormField, RadioFormField } from '@components/atoms/form';

interface VariationPerYearProps {
  yearIdx: number;
  year: number;
  hasSazonalityValue: boolean;
  setValue: UseFormSetValue<CreateEnergyContractPayload>;
}

const VariationPerYear = ({ yearIdx, year, hasSazonalityValue, setValue }: VariationPerYearProps): JSX.Element => {
  const { resetField } = useFormContext<CreateEnergyContractPayload>();
  return (
    <div className="flex gap-6 mb-6">
      {hasSazonalityValue ? (
        <NumericFormField<CreateEnergyContractPayload>
          field={`annualVolumes.${yearIdx}`}
          id="annual-volume"
          label="Volume anual"
          formatProps={{
            placeholder: 'MWm',
            suffix: ' MWm',
            allowNegative: false,
            fixedDecimalScale: true,
            decimalScale: 3,
          }}
          options={{
            disabled: true,
          }}
        />
      ) : (
        <NumericFormField<CreateEnergyContractPayload>
          field={`contractedVolumePerYear.${yearIdx}.volumeAmount`}
          id={`contractedVolumePerYear-${yearIdx}-volumeAmount`}
          label="Volume anual"
          formatProps={{
            placeholder: 'MWm',
            suffix: ' MWm',
            allowNegative: false,
            fixedDecimalScale: true,
            decimalScale: 3,
          }}
          options={{
            required: { value: true, message: 'Campo é obrigatório' },
            onChange: (e) => {
              setValue(`contractedVolumePerYear.${yearIdx}.volumeAmount`, e.target.value);
              setValue(`contractedVolumePerYear.${yearIdx}.year`, year);
              setValue(`contractedVolumePerYear.${yearIdx}.month`, 1);
            },
          }}
        />
      )}
      <RadioFormField<CreateEnergyContractPayload>
        direction="row"
        label="Volume varia por mês?"
        field="hasSazonality"
        valueAsBoolean
        options={{
          onChange: () => {
            resetField(`contractedVolumePerYear`, { defaultValue: [] });
            resetField(`annualVolumes`, { defaultValue: [] });
          },
        }}
        inputOptions={[
          { id: 'YES', optionLabel: 'Sim', value: 'true', defaultChecked: hasSazonalityValue },
          { id: 'NOPE', optionLabel: 'Não', value: 'false', defaultChecked: !hasSazonalityValue },
        ]}
      />
    </div>
  );
};

export default VariationPerYear;
